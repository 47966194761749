<template>
    <div class="dashboard">
	    <Button size="small" color="warning" @click="logout">{{ $t('layout.logout') }}</Button>
    </div>
</template>

<script>

import ApiService from "@/services/api/api.service";
import router from "@/router";
import Button from "@/components/common/Button";

export default {
    name: "Dashboard",
    components: { Button },
    data() {
        return {
            user: null
        };
    },
    methods: {
        check() {
            ApiService.get("user").then(res => {
                this.user = res.data;
            });
        },
        logout() {
            ApiService.post("logout").then(() => {
                router.push("login");
            });
        }
    }
};
</script>
